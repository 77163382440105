import { Divider, Input, Stack, Textarea } from '@chakra-ui/react'
import FormField from 'components/FormField'
import FormSubmitActions from 'components/FormSubmitActions'
import ListBuilder from 'components/ListBuilder'
import PageHeader from 'components/PageHeader'
import React from 'react'
import ChartItem from './ChartItem'
import ManageDashboardEditorBreadcrumb from './ManageDashboardEditorBreadcrumb'
import useManageDashboardEditor from './hooks/useManageDashboardEditor'

const ManageDashboardEditor = ({ data, id }) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    enumerators,
    hasPermissionToEdit,
    onChangeCharts,
    onSubmit,
    isLoading,
    onRemoveChart,
    onAddChart,
    getDashboardPreview,
    previewModalRef,
    isLoadingPreview,
    preview,
    formData
  } = useManageDashboardEditor({
    id,
    data
  })

  const [charts] = watch(['charts_attributes'])

  return (
    <>
      <Stack as="section">
        <PageHeader
          Breadcrumb={
            <ManageDashboardEditorBreadcrumb
              id={id}
              hasPermissionToEdit={true}
            />
          }
          title={
            id && hasPermissionToEdit
              ? 'Edit Dashboard'
              : id && !hasPermissionToEdit
              ? 'View Dashboard'
              : 'New Dashboard'
          }
        />
        <FormField label="Title">
          <Input
            {...register('title', { required: true })}
            placeholder="Title"
          />
        </FormField>
        <FormField label="Description" isRequired={false}>
          <Textarea
            {...register('description')}
            placeholder="Description"
          />
        </FormField>
        <Divider />
        <FormField label="Charts">
          <ListBuilder
            list={charts ?? []}
            resourceName="Chart"
            ItemComponent={ChartItem}
            onChange={onChangeCharts}
            onRemoveItem={onRemoveChart}
            onAddItem={onAddChart}
            customRemoveItemAction={(index, _, fallback) => fallback(index)}
            idAttribute="_id"
            allowDelete={() => true}
            allowDrag={() => true}
            itemProps={{
              id: formData?.dashboard?.data?.attributes?.id,
              register,
              watch,
              setValue,
              getValues,
              enumerators,
              itemKey: 'charts_attributes',
              getDashboardPreview,
              previewModalRef,
              isLoadingPreview,
              preview
            }}
          />
        </FormField>
        <FormSubmitActions
          cancelRoute="manageDashboards"
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </Stack>
    </>
  )
}

export default ManageDashboardEditor
