import { AddIcon, ChevronUpIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  MenuItem,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Tag,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import Dialog from 'components/AlertDialog'
import ColorPicker from 'components/ColorPicker'
import DeleteButton from 'components/DeleteButton'
import FormField from 'components/FormField'
import Sidebar from 'components/Sidebar'
import { cloneDeep, isEmpty, isNull, noop } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import Notification from 'services/Notification'
import DataSettingsItem from '../DataSettingsItem'
import ChangePreviewModal from './ChartPreviewModal'
import ChartSummary from './ChartSummary'

const keys = ['association', 'field', 'operator', 'matchValue']

const ChartItem = ({
  id,
  index,
  register,
  getValues,
  watch,
  setValue,
  enumerators,
  itemKey,
  getDashboardPreview,
  previewModalRef,
  preview,
  isLoadingPreview
}) => {
  const key = `${itemKey}.${index}`

  const [selectedIndex, setSelectedIndex] = useState(null)

  const changeSourceRef = useRef({ open: noop, close: noop })
  const newSourceRef = useRef(null)
  const currentFormValuesRef = useRef({})

  const {
    isOpen: isSidebarOpen,
    onClose: onCloseSidebar,
    onOpen: onOpenSidebar
  } = useDisclosure()

  const [
    chartId,
    name,
    active,
    color,
    source,
    queryMethod,
    aggregationType,
    chartType,
    filterType,
    settings
  ] = watch([
    `${key}.id`,
    `${key}.name`,
    `${key}.active`,
    `${key}.color`,
    `${key}.source`,
    `${key}.form_query_method`,
    `${key}.aggregation_type`,
    `${key}.chart_type`,
    `${key}.filter_type`,
    `${key}.settings`
  ])

  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: !chartId
  })

  const onSelectItem = (index) => {
    if (isNull(index)) {
      const currentItems = getValues(`${key}.settings`) ?? []
      setValue(`${key}.settings`, [...currentItems, {}])
      setSelectedIndex(currentItems.length)
    } else {
      setSelectedIndex(index)
    }

    currentFormValuesRef.current = getValues(`${key}.settings.${index}`)
      ? cloneDeep(getValues(`${key}.settings.${index}`))
      : undefined

    onOpenSidebar()
  }

  const onCloseDrawer = useCallback(() => {
    if (currentFormValuesRef.current) {
      setValue(`${key}.settings.${selectedIndex}`, currentFormValuesRef.current)

      currentFormValuesRef.current = undefined

      onCloseSidebar()
    } else {
      const form = getValues(`${key}.settings.${selectedIndex}`)

      if (!keys.some((key) => !form?.[key]?.value)) {
        onCloseSidebar()
      } else {
        const allSettings = getValues(`${key}.settings`)
        allSettings.splice(selectedIndex, 1)
        setValue(`${key}.settings`, allSettings)

        onCloseSidebar()
      }
    }
  }, [getValues, key, onCloseSidebar, selectedIndex, setValue])

  const onConfirm = () => {
    const form = getValues(`${key}.settings.${selectedIndex}`)

    if (keys.some((key) => !form?.[key]?.value)) {
      return Notification({
        icon: 'error',
        text: 'Please, fill all required fields!'
      })
    } else {
      onCloseSidebar()
    }
  }

  const onDeleteChartData = (index) => {
    const settings = getValues(`${key}.settings`)
    settings.splice(index, 1)
    setValue(`${key}.settings`, settings)
  }

  const onSourceChange = (newSource) => {
    setValue(`${key}.source`, newSource)
    if (!isEmpty(settings)) {
      settings.forEach((_, index) => {
        const settingKey = `${key}.settings`
        setValue(`${settingKey}.${index}.association`, null)
        setValue(`${settingKey}.${index}.field`, null)
        setValue(`${settingKey}.${index}.operator`, null)
        setValue(`${settingKey}.${index}.matchOption`, null)
        setValue(`${settingKey}.${index}.matchValue`, null)
        setValue(`${settingKey}.${index}.matchValueContext`, {})
      })
    }
  }

  const onChangeSource = (newSource) => {
    newSourceRef.current = newSource
    if (
      source &&
      source !== newSource &&
      !isEmpty(getValues(`${key}.settings`))
    ) {
      changeSourceRef.current.open()
    } else {
      onSourceChange(newSource)
    }
  }

  const onChangeQueryMethod = (newQueryMethod) => {
    setValue(`${key}.form_query_method`, newQueryMethod)
  }

  const onChangeAggregationType = (newAggregationType) => {
    setValue(`${key}.aggregation_type`, newAggregationType)
  }

  const onChangeFilterType = (newFilterType) => {
    setValue(`${key}.filter_type`, newFilterType)
    setValue(`${key}.settings`, []);
  }

  const onConfirmChartSouceChange = (onCloseDialog) => {
    onSourceChange(newSourceRef.current)
    setValue(`${key}.settings`, [])
    newSourceRef.current = null
    onCloseDialog()
  }

  const onCancelChartChange = (onCloseDialog) => {
    newSourceRef.current = null
    onCloseDialog()
  }

  return (
    <>
      <Dialog
        ref={changeSourceRef}
        header="Confirm this action"
        body="By proceeding, all settings that were already created will be deleted. Are you sure?"
        continueColor="red"
        continueLabel="Confirm"
        onContinue={onConfirmChartSouceChange}
        handleClose={onCancelChartChange}
      />
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={onCloseDrawer}
        onOpen={onOpenSidebar}
        size="md"
        body={
          <DataSettingsItem
            register={register}
            isRequiredFields={aggregationType === 'specific'}
            watch={watch}
            setValue={setValue}
            enumerators={enumerators}
            itemKey={`${key}.settings`}
            parentKey={key}
            index={selectedIndex}
            getDashboardPreview={getDashboardPreview}
          />
        }
        footer={
          <Button colorScheme="green" onClick={onConfirm}>
            Confirm
          </Button>
        }
        header="Edit filter"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      />
      {!isOpen ? (
        <Stack>
          <SimpleGrid
            columns={chartId && id ? [1, 5] : [1, 4]}
            borderWidth={1}
            borderColor="gray.200"
            tabIndex={0}
            onClick={onToggle}
            cursor="pointer"
            alignItems="center"
            gap={4}
            p={4}
            flexDir={['column', 'row']}
            _hover={{
              borderColor: 'blue.200',
              transition: 'border 0.2s ease'
            }}
          >
            {name && (
              <FormField label="Name">
                <Text>{name}</Text>
              </FormField>
            )}
            {color && (
              <FormField label="Color">
                <Box w="30px" h="20px" borderRadius="md" bg={color} />
              </FormField>
            )}
            {chartType && (
              <FormField
                label="Chart Type"
                infoLabel={enumerators?.descriptions?.dashboard_types}
              >
                <Text>{chartType?.label}</Text>
              </FormField>
            )}
            {!!settings?.length && (
              <FormField label="Amount of Settings">
                <Tag w="max-content" px={4} colorScheme="blue">
                  {settings.length}
                </Tag>
              </FormField>
            )}
            <ChangePreviewModal
              ref={previewModalRef}
              isLoading={isLoadingPreview}
              getDashboardPreview={getDashboardPreview}
              preview={preview}
              chartId={chartId}
              id={id}
            />
          </SimpleGrid>
        </Stack>
      ) : (
        <Stack w="100%" gap={4} id={`chart-${index}`}>
          <Flex gap={4} align="center">
            {chartId && (
              <Button
                variant="ghost"
                onClick={onToggle}
                leftIcon={<ChevronUpIcon />}
              >
                Collapse
              </Button>
            )}
            <ChangePreviewModal
              ref={previewModalRef}
              isLoading={isLoadingPreview}
              getDashboardPreview={getDashboardPreview}
              preview={preview}
              chartId={chartId}
              id={id}
            />
          </Flex>
          <SimpleGrid columns={[1, 2, 4]} alignItems="center" spacing={4}>
            <FormField label="Name">
              <Input
                {...register(`${key}.name`, { required: true })}
                placeholder="Name"
              />
            </FormField>
            <FormField label="Color" isRequired={false}>
              <ColorPicker
                value={color}
                onChange={(newColor) => setValue(`${key}.color`, newColor)}
              />
            </FormField>
            <FormField
              label="Chart type"
              infoLabel={enumerators?.descriptions?.dashboard_types}
            >
              <Select
                {...register(`${key}.chart_type`, { required: true })}
                placeholder="Chart type"
                options={enumerators?.chart_types ?? []}
                value={chartType}
                onChange={(newChartType) =>
                  setValue(`${key}.chart_type`, newChartType)
                }
              />
            </FormField>
            <Switch
              colorScheme="green"
              {...register(`${key}.active`)}
              isChecked={active}
              defaultChecked
              mt={[0, 8]}
            >
              Active
            </Switch>
          </SimpleGrid>
          <Divider />
          <Flex gap={4}>
            <FormField
              label="Source"
              infoLabel={enumerators?.descriptions?.dashboard_source}
            >
              <Select
                {...register(`${key}.source`, { required: true })}
                placeholder="Source"
                options={enumerators?.chart_sources ?? []}
                value={source}
                onChange={onChangeSource}
              />
            </FormField>
            {source?.value && source?.value === 'forms' && (
              <>
                <FormField
                  label="Aggregation Type"
                >
                  <Select
                    {...register(`${key}.aggregation_type`, { required: true })}
                    placeholder="Aggregation Type"
                    options={source?.aggregation_types ?? []}
                    value={aggregationType}
                    onChange={onChangeAggregationType}
                  />
                </FormField>
                <FormField
                  label="Filter type"
                >
                  <Select
                    {...register(`${key}.filter_type`, { required: true })}
                    placeholder="Filter type"
                    options={enumerators?.filter_types ?? []}
                    value={filterType}
                    onChange={onChangeFilterType}
                  />
                </FormField>
              </>
            )}
            {source?.value && source?.value !== 'forms' && (
              <FormField
                label="Query Method"
                infoLabel={enumerators?.descriptions?.chart_methods}
              >
                <Select
                  {...register(`${key}.form_query_method`, { required: true })}
                  placeholder="Query Method"
                  options={enumerators?.chart_methods ?? []}
                  value={queryMethod}
                  onChange={onChangeQueryMethod}
                />
              </FormField>
            )}
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              onClick={() => onSelectItem(null)}
              mt="auto"
              isDisabled={filterType?.value !== 'specific'}
              minW="max-content"
            >
              {settings?.length <= 3
                ? source?.value
                  ? 'Add filter'
                  : 'Select a source first'
                : 'Add filter'}
            </Button>
          </Flex>
        </Stack>
      )}
      <Box my={4}>
        {isSidebarOpen ? (
          <Flex align="center" gap={2}>
            <Text>
              The summary will be displayed as soon the form is correctly filled
            </Text>
            <Spinner size="sm" colorScheme="blue" />
          </Flex>
        ) : (
          <ChartSummary
            settings={settings}
            source={source}
            EditButton={(idx) => (
              <MenuItem
                icon={<EditIcon />}
                variant="ghost"
                size="sm"
                onClick={() => onSelectItem(idx)}
              >
                Edit Chart Data
              </MenuItem>
            )}
            DeleteButton={(idx) => (
              <DeleteButton
                onDelete={onDeleteChartData}
                alertTitle="Delete chart condition"
                alertBody="This selected condition will be deleted. The chart itself won't! Are you sure to proceed?"
                continueLabel="Yes, remove it!"
                size="xs"
                variant="outline"
                borderRadius={6}
                index={idx}
              >
                <MenuItem color="red" icon={<DeleteIcon />}>
                  Delete Chart condition
                </MenuItem>
              </DeleteButton>
            )}
          />
        )}
      </Box>
    </>
  )
}

export default ChartItem
