import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Container } from './styles'
import MaterialIcon from '../MaterialIcon'

const DatePckr = ({
  initialStartDate,
  initialEndDate,
  isRangePicker,
  showTimeSelect,
  onChange,
  isInvalid,
  minW,
  monthsShown,
  withPortal,
  placeholder,
  showYearDropdown,
  leftDistance,
  placement,
  dateLabelFormatter,
  displayPredefinedDates,
  omittedShortcuts
}) => {
  const [startDate, setStartDate] = useState(
    initialStartDate ? new Date(initialStartDate) : null
  )
  const [endDate, setEndDate] = useState(
    initialEndDate ? new Date(initialEndDate) : null
  )

  const shortcuts = useMemo(() => {
    const options = [
      {
        title: 'Today',
        value: 'today'
      },
      {
        title: 'Yesterday',
        value: 'yesterday'
      },
      {
        title: 'Last 7 days',
        value: 'last_7_days'
      },
      {
        title: 'Last 30 days',
        value: 'last_30_days'
      },
      {
        title: 'This week',
        value: 'this_week'
      },
      {
        title: 'This month',
        value: 'this_month'
      },
      {
        title: 'Last month',
        value: 'last_month'
      },
      {
        title: 'Last 3 months',
        value: 'last_3_months'
      },
      {
        title: 'Last 6 months',
        value: 'last_6_months'
      },
      {
        title: 'This year',
        value: 'this_year'
      },
      {
        title: 'Last year',
        value: 'last_year'
      }
    ]
    if (!isRangePicker) {
      return options
        .filter(({ value }) => ['today', 'yesterday'].includes(value))
        .concat([
          { title: 'Next week', value: 'next_week' },
          { title: 'Next month', value: 'next_month' }
        ])
    } else {
      return options.filter(({ value }) => !omittedShortcuts.includes(value))
    }
  }, [isRangePicker, omittedShortcuts])

  const onChangeValue = (dates) => {
    if (isRangePicker) {
      const [start, end] = dates
      if (!start && !end) {
        setStartDate('')
        setEndDate('')
        return onChange([initialStartDate, null])
      } else {
        setStartDate(start)
        setEndDate(end)
        onChange(dates)
      }
    } else {
      setStartDate(dates)
      onChange(dates)
    }
  }

  const handleShortcutsClick = (event, value) => {
    event.stopPropagation()

    const applyDates = (value) => {
      const today = moment().startOf('day')
      const yesterday = moment().startOf('day').subtract(1, 'day')
      const dates = {
        today,
        yesterday,
        todayRange: [today, moment().endOf('day')],
        yesterdayRange: [
          yesterday,
          moment().startOf('day').subtract(1, 'day').endOf('day')
        ],
        last_7_days: [moment().startOf('day').subtract(7, 'day'), today],
        last_30_days: [moment().startOf('day').subtract(30, 'day'), today],
        this_week: [moment().startOf('week'), moment().endOf('week')],
        this_month: [moment().startOf('month'), moment().endOf('month')],
        last_month: [
          moment().startOf('month').subtract(1, 'month'),
          moment().startOf('month').subtract(1, 'day')
        ],
        last_3_months: [moment().startOf('day').subtract(3, 'month'), today],
        last_6_months: [moment().startOf('day').subtract(6, 'month'), today],
        this_year: [moment().startOf('year'), moment().endOf('year')],
        last_year: [
          moment().startOf('year').subtract(1, 'year'),
          moment().endOf('year').subtract(1, 'year')
        ],
        next_month: moment(today).add(1, 'month'),
        next_week: moment(today).add(1, 'week')
      }
      if (isRangePicker) {
        if (['today', 'yesterday'].includes(value)) {
          onChangeValue(dates[`${value}Range`].map((date) => date.toDate()))
        } else {
          onChangeValue(dates[value].map((date) => date.toDate()))
        }
      } else {
        onChangeValue(dates[value].toDate())
      }
    }
    applyDates(value)
  }

  return (
    <Container isRange={isRangePicker} minw={minW} isInvalid={isInvalid}>
      {displayPredefinedDates && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<MaterialIcon icon='calendar_month' />}
            border="none"
            variant="outline"
            mr={2}
            size="sm"
            color="blue.400"
            position="absolute"
            left="0px"
            zIndex="1"
            background="transparent"
          />
          <MenuList>
            {shortcuts.map(({ title, value }) => {
              return (
                <MenuItem
                  key={value}
                  onClick={() => handleShortcutsClick(event, value)}
                >
                  {title}
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      )}
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeValue}
        showTimeSelect={showTimeSelect}
        isClearable
        showYearDropdown={showYearDropdown}
        dateFormat={
          showTimeSelect ? 'dd/MM/yyyy, h:mm aa' : dateLabelFormatter(startDate)
        }
        selectsRange={isRangePicker}
        monthsShown={monthsShown}
        withPortal={withPortal}
        placeholderText={placeholder}
        calendarContainer={({ className, children }) => {
          return (
            <Box position="absolute" left={leftDistance} className={className}>
              {children}
            </Box>
          )
        }}
        popperPlacement={placement}
      />
    </Container>
  )
}

DatePckr.defaultProps = {
  startDate: null,
  endDate: null,
  isRangePicker: false,
  showTimeSelect: true,
  onChange: _.noop,
  minW: '',
  monthsShown: 1,
  isInvalid: false,
  withPortal: false,
  showYearDropdown: true,
  placement: 'top-start',
  displayPredefinedDates: true,
  dateLabelFormatter: () => 'dd/MM/yyyy',
  omittedShortcuts: []
}

DatePckr.propTypes = {
  isInvalid: PropTypes.bool
}

export default DatePckr
