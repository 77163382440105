import { CloseIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Stack,
  Text
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import FormField from 'components/FormField'
import { isEmpty, pick } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { getChartDataSummary, getFirstValueToDefault } from '../hooks/useManageDashboardEditor/helpers'
import MatchValueSelector from './MatchValueSelector'

const DataSettingsItem = ({
  index,
  register,
  watch,
  setValue,
  enumerators,
  itemKey,
  parentKey,
  isRequiredFields = false,
}) => {
  const key = `${itemKey}.${index}`

  const [
    source,
    association,
    field,
    operator,
    matchOption,
    matchValue,
    selections
  ] = watch([
    `${parentKey}.source`,
    `${key}.association`,
    `${key}.field`,
    `${key}.operator`,
    `${key}.matchOption`,
    `${key}.matchValue`,
    `${key}.matchValueContext`
  ])

  const associations = source?.query_associations ?? []
  const fields = useMemo(() => association?.fields ?? [], [association])
  const operators = useMemo(() => field?.operators ?? [], [field])
  const matchOptions = useMemo(() => operator?.match_options ?? [], [operator])

  useEffect(() => {
    setValue(`${key}.field`, field || getFirstValueToDefault(fields))
  }, [fields, field, key, setValue])

  useEffect(() => {
    setValue(`${key}.operator`, operator || getFirstValueToDefault(operators))
  }, [operator, operators, key, setValue])

  useEffect(() => {
    setValue(`${key}.matchOption`, matchOption || getFirstValueToDefault(matchOptions))
  }, [matchOption, matchOptions, key, setValue])
  
  return (
    <Stack p={6}>
      {[
        source?.label,
        field?.label,
        association?.label,
        operator?.label,
        matchValue?.text || matchValue?.label
      ].every((field) => !!field) ? (
        <Alert status="info" variant="left-accent">
          <AlertIcon />
          {getChartDataSummary({
            source,
            field,
            association,
            operator,
            matchOption,
            matchValue
          })}
        </Alert>
      ) : (
        <Text>Fill all fields to visualize the summary</Text>
      )}
      <Box>
        <FormField
          label={`Data Source ${
            isEmpty(associations) ? ' (Select a source)' : ''
          }`}
          infoLabel={enumerators?.descriptions?.chart_data_source}
        >
          <Select
            {...register(`${key}.association`, { required: isRequiredFields })}
            placeholder="Data Source"
            options={associations}
            value={association}
            onChange={(newAssociation) => {
              setValue(`${key}.association`, newAssociation)
              setValue(`${key}.field`, null)
              setValue(`${key}.operator`, null)
              setValue(`${key}.matchOption`, null)
              setValue(`${key}.matchValue`, null)
              setValue(`${key}.matchValueContext`, {})
            }}
          />
        </FormField>
        <FormField
          label={`Field ${isEmpty(fields) ? ' (Select a source)' : ''}`}
          infoLabel={enumerators?.descriptions?.chart_field}
        >
          <Select
            {...register(`${key}.field`, { required: isRequiredFields })}
            placeholder="Field"
            options={fields}
            value={field}
            onChange={(newField) => {
              setValue(`${key}.field`, newField)
              setValue(`${key}.operator`, null)
              setValue(`${key}.matchOption`, null)
              setValue(`${key}.matchValue`, null)
              setValue(`${key}.matchValueContext`, {})
            }}
          />
        </FormField>
        <FormField
          label={`Operator${isEmpty(operators) ? ' (Select a field)' : ''}`}
          infoLabel={enumerators?.descriptions?.chart_operator}
        >
          <Select
            {...register(`${key}.operator`, { required: isRequiredFields })}
            placeholder="Operator"
            options={operators}
            value={operator}
            onChange={(newOperator) => setValue(`${key}.operator`, newOperator)}
          />
        </FormField>
        <FormField
          label={`Match Options${
            isEmpty(matchOptions) ? ' (Select an operator)' : ''
          }`}
          infoLabel={enumerators?.descriptions?.chart_match_options}
          isRequired={false}
        >
          <Select
            {...register(`${key}.matchOption`, { required: isRequiredFields })}
            placeholder="Match Option"
            options={matchOptions ?? []}
            value={matchOption}
            onChange={(newOption) => {
              setValue(`${key}.matchOption`, newOption)
              setValue(`${key}.matchValue`, null)
              setValue(`${key}.matchValueContext`, {})
            }}
          />
        </FormField>
        <FormField
          label="Match Value"
          infoLabel={enumerators?.descriptions?.chart_match_value}
          Action={
            matchValue?.value ? (
              <Button
                onClick={() => setValue(`${key}.matchValue`, null)}
                variant="ghost"
                size="sm"
                leftIcon={<CloseIcon />}
                colorScheme="gray"
              >
                Clear
              </Button>
            ) : null
          }
        >
          <MatchValueSelector
            {...register(`${key}.matchValue.value`, { required: isRequiredFields })}
            itemKey={`${key}.matchValue`}
            register={register}
            value={matchValue}
            onCustomChange={({ target: { value } }) => {
              setValue(`${key}.matchValue.label`, value)
              setValue(`${key}.matchValue.value`, value)
            }}
            onChange={([newValue, selectionsContext]) => {
              setValue(`${key}.matchValue`, pick(newValue, ['value', 'label']))
              setValue(`${key}.matchValueContext`, selectionsContext)
            }}
            association={matchOption}
            selections={selections}
          />
        </FormField>
      </Box>
    </Stack>
  )
}

export default DataSettingsItem
