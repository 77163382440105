import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { getRouteUrl, setFormErrors } from 'helpers'
import { usePageProperties } from 'hooks'
import { debounce, noop } from 'lodash'
import moment from 'moment'
import { normalizeDashboardData } from 'pages/Dashboard/useDashboard/helpers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Notification from 'services/Notification'
import OlingaAPI from 'services/OlingaAPI'
import {
  formatDataToDefaultValues,
  formatDataToSubmit,
  getFirstValueToDefault
} from './helpers'

const initialDefaultValues = {
  title: '',
  description: '',
  layout: '',
  charts_attributes: []
}

const useManageDashboardEditor = ({ id, data }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [preview, setPreview] = useState(null)
  const [{ formData, formId }, setForm] = useState({
    formData: data,
    formId: id
  })

  const history = useHistory()

  const removedItemsRef = useRef([])
  const previewModalRef = useRef({ open: noop, close: noop })

  const getFormDefaultValues = useCallback(
    (formId, dashboard) => {
      return formId
        ? formatDataToDefaultValues(dashboard, data?.enumerators)
        : {
            ...initialDefaultValues
          }
    },
    [data?.enumerators]
  )

  const { defaultValues, enumerators } = useMemo(() => {
    if (data?.enumerators) {
      return {
        enumerators: data.enumerators,
        defaultValues: getFormDefaultValues(formId, formData?.dashboard)
      }
    }
    return {
      enumerators: {}
    }
  }, [data?.enumerators, formData?.dashboard, formId, getFormDefaultValues])

  const { register, watch, setValue, handleSubmit, getValues, reset } = useForm(
    {
      defaultValues
    }
  )

  const { shouldRender: hasPermissionToEdit } = usePageProperties({
    pageName: 'dashboards'
  })

  const onChangeCharts = (newCharts) => {
    setValue('charts_attributes', [...newCharts])
  }

  const onSubmitForm = async (data) => {
    setIsLoading(true)
    try {
      const endpoint = id ? 'updateDashboard' : 'newDashboard'
      const formattedData = formatDataToSubmit(data, removedItemsRef.current)

      const response = await OlingaAPI[endpoint](id, formattedData)

      if (response?.status === 200) {
        Notification({
          icon: 'success',
          text: `Dashboard ${
            response?.data?.data?.id ? 'updated' : 'created'
          } successfully!`
        })

        const updatedForm = response?.data
        const formId = updatedForm?.data?.id

        if (!id && updatedForm?.data?.id) {
          history.replace(getRouteUrl('editDashboard', { id: formId }))
        } else {
          setForm((prev) => ({
            ...prev,
            formData: { dashboard: updatedForm },
            formId
          }))

          reset(getFormDefaultValues(formId, updatedForm))
        }
      }
    } catch (error) {
      setFormErrors(error, noop, Notification)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = () => handleSubmit(onSubmitForm)()

  const onRemoveChart = (_, __, removeItem) => {
    if (removeItem?.id) {
      removedItemsRef.current.push({ ...removeItem, _destroy: true })
    }
  }

  const onAddChart = (item, index) => {
    debounce(() => {
      const chartDiv = document.querySelector(`#chart-${index}`)
      if (chartDiv) {
        chartDiv.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }

      const key = `charts_attributes.${index}`

      const attributes = [
        `${key}.name`,
        `${key}.color`,
        `${key}.source`,
        `${key}.form_query_method`,
        `${key}.chart_type`,
        `${key}.settings`
      ]

      attributes.forEach((attribute) => {
        if (attribute === 'settings') {
          setValue(attribute, [])
        } else if (attribute.includes('name') || attribute.includes('color')) {
          setValue(attribute, '')
        } else if (attribute.includes('source')) {
          setValue(
            `${key}.source`,
            getFirstValueToDefault(enumerators?.chart_sources)
          )
        } else {
          setValue(attribute, null)
        }
      })
    }, 100)()
  }

  const getDashboardPreview = async (dashboardId, chartId) => {
    setIsLoadingPreview(true)
    try {
      const response = await OlingaAPI.getDashboardData(dashboardId, chartId)
      const chartData = response?.data?.attributes
      const { name: info, color } = chartData
      const mockedChartData = {
        total: Array.from({ length: 5 }).reduce(
          (acc, _, index) => (acc += index * 10),
          0
        ),
        xAxis: Array.from({ length: 5 }).map((_, index) =>
          moment().subtract(index, 'day').format('DD/MM')
        ),
        yAxis: Array.from({ length: 5 }).map((_, index) => index * 10)
      }
      const { total, xAxis, yAxis } = mockedChartData
      setPreview({
        ...chartData,
        chartData: normalizeDashboardData(info, color, xAxis, yAxis, total)
      })
    } catch (error) {
      //
    } finally {
      setIsLoadingPreview(false)
    }
  }

  useEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend,
      ChartDataLabels
    )

    return () => {
      ChartJS.unregister()
    }
  }, [])

  return {
    register,
    watch,
    setValue,
    getValues,
    enumerators,
    hasPermissionToEdit,
    onChangeCharts,
    isLoading,
    onSubmit,
    onRemoveChart,
    onAddChart,
    getDashboardPreview,
    preview,
    previewModalRef,
    isLoadingPreview,
    formData
  }
}

export default useManageDashboardEditor
