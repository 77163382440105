import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { difference, isEmpty, isNumber } from 'lodash'
import moment from 'moment'
import { normalizeDashboardData } from 'pages/Dashboard/useDashboard/helpers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import OlingaAPI from 'services/OlingaAPI'

const defaultFilter = { name: 'today', params: {} }

const useDashboard = ({ data }) => {
  const { dashboards } = useMemo(() => {
    return {
      dashboards: data?.dashboards?.map(({ attributes }) => {
        return {
          ...attributes,
          charts: attributes?.charts?.data?.map(
            ({ attributes: chartAttributes }) => ({
              ...chartAttributes
            })
          )
        }
      })
    }
  }, [data])

  const [dashboardCharts, setDashboardCharts] = useState({})

  const [loadingIndex, setLoadingIndex] = useState(0)

  const [index, setIndex] = useState([0])

  const [filter, onChangeFilter] = useState(
    dashboards?.reduce((acc, _, index) => {
      acc[index] = defaultFilter
      return acc
    }, {})
  )

  const fetchChartsData = useCallback(
    async (index, filters) => {
      const charts = dashboards?.[index]?.charts ?? []

      const getDashboardPreview = async (
        dashboardId,
        chartId,
        filters,
        isMocked = true
      ) => {
        try {
          const response = await OlingaAPI.getDashboardData(
            dashboardId,
            chartId,
            filters
          )
          const chartData = response?.data?.attributes
          const {
            name: info,
            color,
            chartData: {
              value: {
                total: chartDataTotal,
                x_axis: chartDataXAxis,
                y_axis: chartDataYAxis
              }
            }
          } = chartData
          const mockedChartData = {
            total: Array.from({ length: 5 }).reduce(
              (acc, _, index) => (acc += index * 10),
              0
            ),
            xAxis: Array.from({ length: 5 }).map((_, index) =>
              moment().subtract(index, 'day').format('DD/MM')
            ),
            yAxis: Array.from({ length: 5 }).map((_, index) => index * 10)
          }
          return {
            ...chartData,
            chartData: normalizeDashboardData(
              info,
              color,
              isMocked ? mockedChartData?.xAxis : chartDataXAxis,
              isMocked ? mockedChartData?.yAxis : chartDataYAxis,
              isMocked ? mockedChartData?.total : chartDataTotal
            )
          }
        } catch (error) {
          //
        }
      }

      if (!isEmpty(charts)) {
        setLoadingIndex(index)
        try {
          const promises = charts.map(({ id, dashboardId }) =>
            getDashboardPreview(dashboardId, id, filters, false)
          )
          const filledCharts = await Promise.all(promises)

          setDashboardCharts((prev) => ({
            ...prev,
            [index]: {
              ...prev?.[index],
              items: filledCharts
            }
          }))
        } catch (error) {
          //
        } finally {
          setLoadingIndex(null)
        }
      }
    },
    [dashboards]
  )

  useEffect(() => {
    fetchChartsData(0, defaultFilter)
  }, [fetchChartsData])

  const onExpandDashboard = (expandedIndex) => {
    const currentExpandedIndex = index
    const [fetchIndex] = difference(expandedIndex, currentExpandedIndex)
    if (isNumber(fetchIndex) && isEmpty(dashboardCharts?.[fetchIndex]?.items)) {
      fetchChartsData(fetchIndex, filter?.[fetchIndex] ?? defaultFilter)
    }

    setIndex(expandedIndex)
  }

  const onChangeFilters = (index, newFilter) => {
    onChangeFilter((prev) => ({
      ...prev,
      [index]: {
        ...newFilter
      }
    }))

    fetchChartsData(index, newFilter)
  }

  useEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend,
      ChartDataLabels
    )

    return () => {
      ChartJS.unregister()
    }
  }, [])

  return {
    dashboards,
    loadingIndex,
    dashboardCharts,
    onExpandDashboard,
    index,
    filter,
    onChangeFilters
  }
}

export default useDashboard
