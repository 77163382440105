import { Text } from '@chakra-ui/react'
import { camelCase, omit, pick, first } from 'lodash'
import React from 'react'
import { v4 as uuid } from 'uuid'

const validContexts = [
  'selectedFolder',
  'selectedTemplate',
  'selectedQuestion',
  'selectedQuestionOption',
  'selectedAssetTemplate',
  'selectedAssetTemplateField',
  'selectedAssetTemplateFieldOption'
]

export const getFirstValueToDefault = (array) => {
  if (array?.length === 1) {
    return first(array)
  }

  return null
}

export const formatDataToDefaultValues = (initialData, enumerators) => {
  const { id, attributes } = initialData.data

  const extractItemFromEnumerator = (itemValue, enumeratorId) =>
    enumerators?.[enumeratorId]?.find(({ value }) => value === itemValue)

  const extractItemFromSubList = (itemValue, list) =>
    list.find(({ value }) => value === itemValue)

  return omit(
    {
      id,
      ...attributes,
      layout: extractItemFromEnumerator(attributes.layout, 'chart_layouts'),
      charts_attributes: (attributes?.charts?.data ?? []).map(
        ({ attributes: chart }) => {
          const source = extractItemFromEnumerator(
            chart.source,
            'chart_sources'
          )
          const formQueryMethod = extractItemFromEnumerator(
            chart?.settings?.form_query_method,
            'chart_methods'
          )
          return {
            ...chart,
            _id: uuid(),
            source,
            aggregation_type: extractItemFromSubList(
              chart?.settings?.aggregation_type,
              source?.aggregation_types ?? []
            ),
            filter_type: extractItemFromEnumerator(
              chart.filterType,
              'filter_types'
            ),
            form_query_method: formQueryMethod,
            chart_type: extractItemFromEnumerator(
              chart.chartType,
              'chart_types'
            ),
            settings: (chart?.settings?.query ?? []).map((chartItem) => {
              const association = extractItemFromSubList(
                chartItem?.model,
                source?.query_associations ?? []
              )
              const field = extractItemFromSubList(
                chartItem?.field,
                association?.fields ?? []
              )
              const operator = extractItemFromSubList(
                chartItem?.operator,
                field?.operators ?? []
              )

              return {
                ...chartItem,
                _id: uuid(),
                association: extractItemFromSubList(
                  chartItem?.model,
                  source?.query_associations ?? []
                ),
                operator,
                field,
                matchOption: chartItem?.match?.match_option || null,
                matchValueContext: pick(
                  Object.entries(
                    chartItem?.match?.match_value_context || {}
                  ).reduce((acc, [k, v]) => {
                    acc[camelCase(k)] = v
                    return acc
                  }, {}),
                  validContexts
                ),
                matchValue: {
                  label:
                    chartItem?.match?.summary_text || chartItem?.match?.value,
                  value: chartItem?.match?.value
                }
              }
            })
          }
        }
      )
    },
    ['businessId', 'charts']
  )
}

export const formatDataToSubmit = (data, removedItems = []) => {
  return {
    ...data,
    layout: data?.layout?.value,
    charts_attributes: [
      ...data.charts_attributes.map((attributes, index) => {
        return omit(
          {
            ...attributes,
            order: index + 1,
            chartType: attributes?.chart_type?.value,
            filterType: attributes?.filter_type?.value,
            source: attributes?.source?.value,
            settings: {
              form_query_method: attributes?.form_query_method?.value,
              aggregation_type: attributes?.aggregation_type?.value,
              query: attributes?.settings?.map((setting) => {
                return {
                  model: setting?.association?.value,
                  field: setting?.field?.value,
                  operator: setting?.operator?.value,
                  match: {
                    type: 'association_value',
                    value: ['ilike'].includes(setting?.operator?.value)
                      ? setting?.matchValue?.label
                      : setting?.matchValue?.value,
                    summary_text: setting?.matchValue?.label,
                    matchOption: setting?.matchOption,
                    matchValueContext: pick(
                      Object.entries(setting?.matchValueContext ?? {}).reduce(
                        (acc, [k, v]) => {
                          acc[k] = pick(v, [
                            'value',
                            'template_question_options',
                            'label',
                            'text',
                            'id'
                          ])
                          return acc
                        },
                        {}
                      ),
                      validContexts
                    )
                  }
                }
              })
            }
          },
          [
            '_id',
            'chart_type',
            'filter_type',
            'aggregation_type',
            'form_query_method'
          ]
        )
      }),
      ...removedItems
    ]
  }
}

export const getChartDataSummary = (data) => {
  const { source, association, operator, matchOption, matchValue } = data
  const associationShortHand = {
    template: 'Template'
  }
  const matchOptionShortHand = {
    asset_template_field_option: `'s option`,
    template: ''
  }

  return (
    <Text>
      <b>{source?.label}</b>
      {` `}where{` `}
      <b>
        {(associationShortHand[matchOption?.value] ?? association?.label) + ' '}
      </b>
      {matchOptionShortHand[matchOption?.value] ?? matchOption?.label}{' '}
      {operator?.label}{' '}
      <b>{`"${matchValue?.label || matchValue?.text}"` ?? '-'}</b>
    </Text>
  )
}
