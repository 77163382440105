/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { IconButton, Tooltip } from '@chakra-ui/react'
import { Chart as ChartJS } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
import MaterialIcon from 'components/MaterialIcon'
import { map } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import DashboardBox from '../DashboardBox'
import { actions } from './helpers'

const Chart = ({
  dashboardData,
  title,
  defaultType,
  shouldChangeFormatView,
  height,
  ...rest
}) => {
  const data = dashboardData.data
  const total = dashboardData.total
  const [formatView, setFormatView] = useState(defaultType)
  const chartRef = useRef(null)

  useEffect(() => {
    ChartJS.register(zoomPlugin)
  }, [])

  const DashboardComponent = useMemo(
    () => (formatView === 'bar' ? Bar : Line),
    [formatView]
  )

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          precision: 0
        },
        beginAtZero: true,
        max: Math.max(...(data?.datasets?.[0]?.data ?? [])) + 10
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      tooltip: {
        enabled: false
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: false
          },
          drag: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      },
      title: {
        display: true,
        text: `${total ? `Total: ${total}` : ''}`,
        font: {
          size: '18px'
        },
        padding: {
          bottom: 24
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (v) => (v ? Math.round(v) : ''),
        font: {
          weight: 'bold'
        }
      }
    }
  }

  const onChangeType = (value) => {
    setFormatView(value)
  }

  const chartHeader = useMemo(() => {
    const ZoomActions = (
      <Box w="95px" alignItems="center" mb={4}>
        <Text fontSize="sm">Zoom:</Text>
        <Grid templateColumns="repeat(3, 1fr)" gap={1}>
          {map(actions, (action) => (
            <GridItem>
              <IconButton
                size="xs"
                aria-label={action.name}
                onClick={() => action.handler(chartRef?.current)}
                icon={
                  <Tooltip label={action.name}>
                    <span>
                      <MaterialIcon
                        icon={action.icon}
                        styles={{ fontSize: '14px' }}
                      />
                    </span>
                  </Tooltip>
                }
              />
            </GridItem>
          ))}
        </Grid>
      </Box>
    )

    if (shouldChangeFormatView) {
      return (
        <Flex justifyContent="space-between">
          <Flex alignItems="center" mb={4}>
            <Text fontSize="sm">Chart type:</Text>
            <RadioGroup size="sm" onChange={onChangeType} value={formatView}>
              <Flex pl={4} mt="8px" w={120} justify="space-between">
                <Radio value="bar">Bar</Radio>
                <Radio value="line">Line</Radio>
              </Flex>
            </RadioGroup>
          </Flex>
          {ZoomActions}
        </Flex>
      )
    }

    return ZoomActions
  }, [formatView, shouldChangeFormatView])

  return (
    <>
      <DashboardBox title={title} header={chartHeader} {...rest}>
        <DashboardComponent
          ref={chartRef}
          options={options}
          data={data}
          height={height}
        />
      </DashboardBox>
    </>
  )
}

Chart.defaultProps = {
  shouldChangeFormatView: false
}

export default Chart
