import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Tag,
  Text
} from '@chakra-ui/react'
import InfoTip from 'components/Infotip'
import { isEmpty } from 'lodash'
import React from 'react'
import { getChartDataSummary } from '../../hooks/useManageDashboardEditor/helpers'

const ChartSummary = ({
  settings,
  source,
  EditButton = null,
  DeleteButton = null
}) => {
  if (isEmpty(settings)) {
    if (source) {
      return (
        <Text>
          -&nbsp; All Business <b>{source?.label + "'s"}</b>
        </Text>
      )
    }
    return <Text>Select a source to display the chart summary</Text>
  }
  return (
    <Stack>
      <Heading size="xs">
        Summary{' '}
        <InfoTip label="The chart will display the data based on all selected conditions" />
      </Heading>
      <Flex gap={4} align="center" flexWrap="wrap">
        {settings.map((form, index) => {
          return (
            <Flex key={index} align="center" gap={4}>
              <Menu>
                <MenuButton
                  variant="ghost"
                  as={Button}
                  size="sm"
                  rightIcon={<ChevronDownIcon />}
                >
                  {getChartDataSummary({ ...(form ?? {}), source })}
                </MenuButton>
                <MenuList>
                  {EditButton && EditButton(index)}
                  {DeleteButton && DeleteButton(index)}
                </MenuList>
              </Menu>
              {index < settings?.length - 1 && (
                <Tag px={4} textTransform="uppercase" colorScheme="green">
                  and
                </Tag>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Stack>
  )
}

export default ChartSummary
