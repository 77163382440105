import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import moment from 'moment'
import ChartPreview from 'pages/ManageDashboards/ManageDashboardEditor/ChartItem/ChartPreview'
import React from 'react'
import Filters from './Filters'
import useDashboard from './hooks/useDashboard'

const Dashboard = ({ data }) => {
  const {
    dashboards,
    dashboardCharts,
    loadingIndex,
    index,
    onExpandDashboard,
    filter,
    onChangeFilters
  } = useDashboard({ data })
  return (
    <Stack>
      <Heading size="md">Dashboards</Heading>
      <Divider />
      <Accordion
        defaultIndex={[0]}
        allowMultiple
        index={index}
        onChange={onExpandDashboard}
      >
        {dashboards?.map(({ id, title, charts }, index) => {
          const [startDate, endDate] = filter?.[index]?.params?.date ?? []
          return (
            <Stack minH="100px" position="relative" key={id}>
              <AccordionItem
                key={id}
                borderWidth={1}
                my={4}
                borderRadius="md"
                position="relative"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton as="h2">
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        py={4}
                        fontSize="lg"
                        fontWeight="bold"
                      >
                        {title}{' '}
                        {filter?.[index]?.name !== 'custom'
                          ? `(${startCase(filter?.[index]?.name)})`
                          : `(From ${moment(startDate).format(
                              'DD/MM/YYYY'
                            )} to ${moment(endDate).format('DD/MM/YYYY')})`}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    {isExpanded ? (
                      <AccordionPanel pb={4} position="relative">
                        <>
                          <Flex flexDirection="column" gap={4} w="100%">
                            <Filters
                              value={filter?.[index]}
                              onChange={(filter) =>
                                onChangeFilters(index, filter)
                              }
                              isDisabled={loadingIndex === index}
                            />
                            <SimpleGrid
                              minChildWidth="40%"
                              spacing={5}
                              flex={1}
                            >
                              {loadingIndex === index
                                ? charts?.map(({ chartType }, index) => {
                                    return (
                                      <Skeleton
                                        h={
                                          chartType === 'number'
                                            ? '150px'
                                            : '380px'
                                        }
                                        w="100%"
                                        key={index}
                                      />
                                    )
                                  })
                                : dashboardCharts?.[index]?.items?.map(
                                    (preview, index) => {
                                      return (
                                        <ChartPreview
                                          key={index}
                                          preview={preview}
                                        />
                                      )
                                    }
                                  )}
                            </SimpleGrid>
                          </Flex>
                        </>
                      </AccordionPanel>
                    ) : null}
                  </>
                )}
              </AccordionItem>
            </Stack>
          )
        })}
      </Accordion>
    </Stack>
  )
}

export default Dashboard
