import axios from 'axios'
import { isEmpty, omit, orderBy, pick } from 'lodash'
import { serialize } from 'object-to-formdata'
import api, { handleErrors, handleNotificationError } from './api'

const CancelToken = axios.CancelToken
export let cancelSource = CancelToken.source()
export let requestsPending = 0

axios.interceptors.request.use(
  function (config) {
    requestsPending++
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    requestsPending--
    cancelSource = CancelToken.source()
    return response
  },
  function (error) {
    requestsPending--
    cancelSource = CancelToken.source()
    return Promise.reject(error)
  }
)

const getFormDataFromObject = (object, params = {}) =>
  serialize(object, {
    indices: false,
    ...params
  })

const OlingaAPI = {
  custom: {
    staff: {
      additionalFields: async () => {
        try {
          const response = await axios.get(
            '/web/api/businesses/staff/additional_fields.json'
          )
          return response.data.data
        } catch (error) {
          throw handleErrors(error)
        }
      }
    },
    dashboards: {
      totalVehicles: async (params) => {
        try {
          const response = await axios.get(
            '/web/api/dashboards/total_vehicles.json',
            { params }
          )
          return response.data.data
        } catch (error) {
          throw handleErrors(error)
        }
      }
    }
  },
  alertsList: async (params) => {
    try {
      const response = await axios.get('/web/api/alerts.json', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  alertShow: async (alertID) => {
    try {
      const response = await api.get(`/web/api/alerts/${alertID}.json`)

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  alertMarkAsSeen: async (alertID) => {
    try {
      const response = await api.post(
        `/web/api/alerts/${alertID}/mark_as_seen.json`,
        {}
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  templateFormFolderList: async (params) => {
    try {
      const response = await api.get(
        '/web/api/form_folders/templates/folders.json',
        {
          params
        }
      )

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  templateShow: async (templateId) => {
    try {
      const response = await api.get(`/web/api/templates/${templateId}.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  cloneTemplate: async (id) => {
    try {
      const response = await api.get(`/web/api/templates/${id}/clone`, {})

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  templatesCreate: async (formData) => {
    try {
      const response = await api.post('/web/api/templates.json', formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
      return error
    }
  },

  templatesUpdate: async (template, formData) => {
    try {
      const response = await api.put(
        `/web/api/templates/${template.id}.json`,
        formData
      )

      return response
    } catch (error) {
      handleErrors(error)
      return error
    }
  },

  templateToggleActive: async (templateId) => {
    try {
      const response = await api.put(
        `/web/api/templates/${templateId}/toggle_active.json`
      )

      return response.data
    } catch (error) {
      handleErrors(error)
    }
  },

  templateEditorEnumerators: async () => {
    try {
      const response = await api.get(`/web/api/templates/enumerators.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  templateDetails: async (templateId) => {
    try {
      const response = await api.get(
        `/api/v1/template/${templateId}/details.json`
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  groupsList: async (_, params) => {
    try {
      const response = await api.get('/web/api/groups.json', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  groupsEnumerators: async () => {
    try {
      const response = await api.get('/web/api/groups/as_enumerators')
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  groupsSelectOptionsList: async (folderId) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/${folderId}/groups.json`
      )
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  groupsCreate: async (formData) => {
    try {
      const response = await api.post('/web/api/groups.json', formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  getGroup: async (groupId, params) => {
    try {
      const response = await api.get(`/web/api/groups/${groupId}.json`, {
        params
      })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  groupsUpdate: async (groupId, formData) => {
    try {
      const response = await api.put(
        `/web/api/groups/${groupId}.json`,
        formData,
        {}
      )

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  employeesList: async () => {
    try {
      const response = await api.get('/web/api/businesses/employees.json')

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  folderTreeList: async (params) => {
    try {
      const response = await api.get(
        '/web/api/form_folders/documents/folders',
        {
          params
        }
      )

      return response.data?.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  allFolders: async (params) => {
    try {
      const response = await api.get('/web/api/form_folders.json', {
        params
      })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  formFolderList: async (_, params) => {
    try {
      const response = await api.get('/web/api/form_folders.json', { params })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  getFormFolder: async (formFolderId, params) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/${formFolderId}.json`,
        {
          params
        }
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  formFolderTemplatesList: async (formFolderId, params, keepAlive = true) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/${formFolderId}/templates.json`,
        { params }
      )

      return response.data
    } catch (error) {
      if (keepAlive) {
        return null
      }
      handleNotificationError(error)
    }
  },

  formFolderCreate: async (formData) => {
    try {
      const response = await api.post(
        '/web/api/form_folders.json',
        formData,
        {}
      )

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  formFolderUpdate: async (id, formData) => {
    try {
      const response = await api.put(
        `/web/api/form_folders/${id}.json`,
        formData,
        {}
      )

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  formExport: async (templateId, formData, fileType) => {
    try {
      const response = await api({
        method: 'GET',
        url: `/web/api/templates/${templateId}/forms/export.${fileType}`,
        params: formData
      })

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  formFolderDocumentsList: async (id, params, keepAlive = true) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/${id}/documents.json`,
        {
          params
        }
      )

      return response.data
    } catch (error) {
      if (keepAlive) {
        return null
      }
      handleNotificationError(error)
    }
  },

  formFolderDocument: async (formFolderId, id, params) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/${formFolderId}/documents/${id}.json`,
        { params }
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  formDetail: async (templateId, formId) => {
    try {
      const response = await api.get(
        `/web/api/templates/${templateId}/forms/${formId}.json`
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  formsList: async (templateId, params) => {
    try {
      const result = await axios.get(
        `/web/api/templates/${templateId}/forms.json`,
        { params, cancelToken: cancelSource.token }
      )

      return result
    } catch (error) {
      handleNotificationError(error)
    }
  },

  businessSubUsersList: async (email = '', params = {}) => {
    try {
      let allParams = {
        ...params
      }
      if (email) {
        allParams = {
          ...allParams,
          email
        }
      }
      const response = await api.get(
        `/web/api/businesses/sub_users_list.json`,
        {
          params: allParams
        }
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  submitForm: async (templateId, object) => {
    const formData = getFormDataFromObject(object)
    try {
      const response = await api.post(
        `/web/api/templates/${templateId}/forms/submit_form.json`,
        formData
      )

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  createDocument: async (object) => {
    const formData = getFormDataFromObject(object)
    try {
      const response = await api.post(`/web/api/documents.json`, formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  editDocument: async (id, object) => {
    const formData = getFormDataFromObject(object)
    try {
      const response = await api.put(
        `/web/api/documents/${id}.json`,
        formData,
        {}
      )

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  documentFolders: async (id) => {
    try {
      const response = await api.get(`/web/api/documents/${id}/folders`)
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  documentsIndex: async (_, params) => {
    try {
      const response = await api.get(`/web/api/documents.json`, { params })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  getDashboardsData: async ({ requests, params }) => {
    try {
      const fetchController = {
        promises: [],
        actors: []
      }
      const { employees, forms } = requests
      if (employees) {
        fetchController.promises.push(
          api.get('/web/api/dashboards/forms/employees.json', { params })
        )
        fetchController.actors.push('employees')
      }
      if (forms) {
        fetchController.promises.push(
          api.get('/web/api/dashboards/forms.json', { params })
        )
        fetchController.actors.push('forms')
      }
      if (isEmpty(fetchController.promises)) {
        return []
      }
      const response = await Promise.all(fetchController.promises)
      const data = fetchController.actors.reduce((acc, actor, index) => {
        acc[actor] = response[index].data.data
        return acc
      }, {})

      return data
    } catch (error) {
      throw handleErrors(error)
    }
  },

  templatesList: async () => {
    try {
      const response = await api.get(`/web/api/templates.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  updateUserRoles: async (userId, formData) => {
    try {
      const response = await api.post(
        `/web/api/businesses/${userId}/update_roles.json`,
        formData
      )

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  newUser: async (_, formData) => {
    try {
      const response = await api.post(
        `/web/api/users/create_user_business.json`,
        formData
      )

      return response
    } catch (error) {
      return error
    }
  },

  profilesList: async (_, params) => {
    try {
      const response = await api.get(`/web/api/profiles.json`, { params })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  profilesEnumerators: async () => {
    try {
      const response = await api.get(`/web/api/profiles/as_enumerators.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  profileShow: async (profileId) => {
    try {
      const response = await api.get(`/web/api/profiles/${profileId}.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  profileRolesList: async (params) => {
    try {
      const response = await api.get(`/web/api/profiles/roles_list.json`, {
        params: {
          grouped: params.isGrouped
        }
      })
      if (params.isGrouped) {
        response.data = {
          ...(response?.data ?? {}),
          data: {
            ...omit(response?.data, ['report']),
            ...response?.data?.report?.reduce(
              (acc, role) => {
                if (['report_modify', 'report_view'].includes(role?.name)) {
                  acc.scheduled_report.push(role)
                } else if (role?.name?.includes('report_summary')) {
                  acc.summary_report.push(role)
                }
                return acc
              },
              { summary_report: [], scheduled_report: [] }
            )
          }
        }
      }
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  profileCreate: async (formData) => {
    try {
      const response = await api.post('/web/api/profiles', formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  managerUserShow: async (userId) => {
    try {
      const response = await api.get(
        `/web/api/profiles/manage_user/${userId}.json`
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  profileUpdate: async (profileId, formData) => {
    try {
      const response = await api.put(
        `/web/api/profiles/${profileId}`,
        formData,
        {}
      )

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  profileDelete: async (profileId) => {
    try {
      const response = await api.delete(
        `/web/api/profiles/${profileId}.json`,
        {}
      )

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  searchDocuments: async (params) => {
    try {
      const response = await api.get('/web/api/documents/search', params)
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  searchTemplates: async (params) => {
    try {
      const response = await api.get(
        '/web/api/templates/template_search',
        params
      )
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  searchChecklists: async (params) => {
    try {
      const response = await api.get('/web/api/checklists/search', params)
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  signIn: async (params) => {
    const formData = getFormDataFromObject(params)

    try {
      const response = api.post('users/sign_in', formData)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },

  newPassword: async (params) => {
    const formData = getFormDataFromObject(params)

    try {
      const response = api.post('users/password', formData)
      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  resetPassword: async (formData) => {
    try {
      const response = await api.put('users/password', formData)
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  editUserPassword: async (params) => {
    try {
      const response = await api.put('users', { user: { ...params } })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  assessorsList: async (_, params) => {
    try {
      const response = await api.get('/web/api/assessors.json', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  getAssessor: async (id, params) => {
    try {
      const response = await api.get(`/web/api/assessors/${id}.json`, {
        params
      })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  assessorsUpdate: async (id, formData) => {
    try {
      const response = await api.put(`/web/api/assessors/${id}`, formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  assessorsCreate: async (formData) => {
    try {
      const response = await api.post('/web/api/assessors', formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  assessorsToggleActive: async (id, formData) => {
    try {
      const response = await api.put(
        `/web/api/assessors/${id}/toggle_active`,
        formData
      )

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  actionsEnums: async () => {
    try {
      const response = await api.get(`/web/api/actions/enumerators.json`)

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  actionsList: async (_, params) => {
    try {
      const response = await api.get(`/web/api/actions.json`, {
        params
      })

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  createAction: async (formData) => {
    try {
      const response = await api.post(`/web/api/actions/`, {
        action_params: formData
      })

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  editAction: async (formData, actionId) => {
    try {
      const response = await api.put(`/web/api/actions/${actionId}.json`, {
        action_params: formData
      })

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  deleteAction: async (actionId) => {
    try {
      const response = await api.delete(`/web/api/actions/${actionId}`)
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  getAction: async (actionId) => {
    try {
      const response = await api.get(`/web/api/actions/${actionId}.json`, {})

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  staffSearch: async (staffName, shouldReturnEmailInput, params = {}) => {
    params = {
      staff_name: staffName,
      ...params
    }
    try {
      const response = await api.get('/web/api/staff', { params })
      if (shouldReturnEmailInput) {
        if (response.data?.data?.length) {
          return response.data
        }
        if (
          staffName.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          )
        ) {
          return {
            data: [
              {
                attributes: {
                  id: staffName,
                  name: staffName
                }
              }
            ]
          }
        }
      } else {
        return response.data
      }
    } catch (error) {
      handleNotificationError(error)
    }
  },

  emailInput: async (email) => {
    try {
      if (email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        return {
          data: [
            {
              attributes: {
                id: email,
                name: email
              }
            }
          ]
        }
      } else {
        return {
          data: []
        }
      }
    } catch (error) {
      handleNotificationError(error)
    }
  },

  usersToggleActive: async (id, formData) => {
    try {
      const response = await api.put(
        `/web/api/users/${id}/toggle_active`,
        formData
      )
      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  librariesList: async (params) => {
    try {
      const response = await api.get('/web/api/libraries.json', params, {})
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  showLibrary: async (id) => {
    try {
      const response = await api.get(`/web/api/libraries/${id}.json`, {})

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  librariesDocuments: async (id) => {
    try {
      const response = await api.get(
        `/web/api/libraries/${id}/documents.json`,
        {}
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  libraryDocument: async (id) => {
    try {
      const response = await api.get(`/web/api/documents/${id}.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  createLibrary: async (data) => {
    const formData = getFormDataFromObject(data)
    try {
      const response = await api.post(`/web/api/libraries.json`, formData, {})

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  editLibrary: async (id, data) => {
    try {
      const response = await api.put(`/web/api/libraries/${id}`, data, {})

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  handleDeleteEditTemplateElement: async ({
    type,
    templateId,
    elementId,
    selectedContentValue
  }) => {
    try {
      let url = `/web/api/templates/${templateId}/template_structures/${type}/destroy/${elementId}`
      if (type === 'section_question' && selectedContentValue) {
        url += `?except_descendants=${selectedContentValue}`
      } else if (type === 'question_logic') {
        url += `?selected_option=${selectedContentValue}`
      }
      const response = await api.delete(url, {})
      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },

  templateStructure: async (id, params = {}) => {
    try {
      const response = await api.get(
        `/web/api/templates/${id}/template_structure.json`,
        { params }
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  groupPeople: async (id) => {
    try {
      const response = await api.get(`/web/api/groups/${id}/people`, {})
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  reportEnumerators: async (reportType) => {
    try {
      const response = await api.get(
        `/web/api/template_reports/${reportType}/enumerators`
      )
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  reportShow: async (reportId, reportType) => {
    try {
      const response = await api.get(
        `/web/api/template_reports/${reportType}/${reportId}.json`
      )
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  editReport: async (reportId, reportType) => {
    try {
      const response = await api.get(
        `/web/api/template_reports/${reportType}/${reportId}.json`
      )
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  createReport: async (reportType, formData) => {
    try {
      const response = await api.post(
        `/web/api/template_reports/${reportType}`,
        formData,
        {}
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  updateReport: async (reportType, formData, id) => {
    try {
      const response = await api.put(
        `/web/api/template_reports/${reportType}/${id}`,
        formData,
        {}
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  reportsList: async (_, params) => {
    try {
      const response = await api.get(
        `/web/api/template_reports/${params.reportType}.json`,
        {
          params
        }
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  downloadReport: async (id, reportType) => {
    try {
      const response = await api({
        method: 'GET',
        url: `/web/api/template_reports/${reportType}/${id}/export.pdf`,
        params: {}
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  resendReportEmails: async (id, emails = [], reportType) => {
    try {
      const response = await api.post(
        `/web/api/template_reports/${reportType}/${id}/send_mail`,
        {
          emails
        }
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  reportDelete: async (reportId, reportType) => {
    try {
      const response = await api.delete(
        `/web/api/template_reports/${reportType}/${reportId}`,
        {}
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  reportTemplates: async (reportId, reportType) => {
    try {
      const response = await api.get(
        `/web/api/template_reports/${reportType}/${reportId}/templates`,
        {}
      )

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },

  getTemplateFrequencies: async (templateId, params) => {
    try {
      const response = await api.get(
        `/web/api/templates/${templateId}/template_frequency_settings.json`,
        { params }
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  getTemplateFrequenciesDetails: async (templateId, id) => {
    try {
      const response = await api.get(
        `/web/api/templates/${templateId}/template_frequency_settings/${id}.json`
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  getTemplateFrequenciesEnums: async (templateId) => {
    try {
      const response = await api.get(
        `/web/api/templates/${templateId}/template_frequency_settings/enums.json`
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  createTemplateFrequencySettings: async (templateId, data) => {
    try {
      const response = await api.post(
        `/web/api/templates/${templateId}/template_frequency_settings`,
        data
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  editTemplateFrequencySettings: async (id, templateId, data) => {
    try {
      const response = await api.put(
        `/web/api/templates/${templateId}/template_frequency_settings/${id}`,
        data
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  templateFrequencySettingsCharts: async (templateId, dateRange) => {
    try {
      let templateParams = templateId ? `&template_id=${templateId}` : ''
      const response = await api.get(
        `/web/api/form_frequencies/status_dashboard.json?date_range=${dateRange}${templateParams}`
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getUserIsSigned: async () => {
    try {
      const response = await api.get('/users/user_signed_in.json')

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getChecklist: async (checklistId, params) => {
    try {
      const response = await api.get(
        `/web/api/checklists/${checklistId}.json`,
        { params }
      )

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  getChecklistEnums: async () => {
    try {
      const response = await api.get(`/web/api/checklists/enumerators.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },

  createChecklist: async (data) => {
    try {
      const response = await api.post(`/web/api/checklists.json`, data)

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  editChecklist: async (id, data) => {
    try {
      const response = await api.put(`/web/api/checklists/${id}.json`, data)

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  checklistsFolders: async (params) => {
    try {
      const response = await api.get(`/web/api/checklists/folders.json`, {
        params
      })
      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getChecklistsFromFolders: async (formFolderId, params = {}) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/${formFolderId}/checklists.json`,
        { params }
      )

      return { ...response.data, meta: response?.data?.meta?.pagy_metadata }
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getUsersTasks: async (checklistId, checklistItemId, params = {}) => {
    try {
      const response = await api.get(
        `/web/api/checklists/${checklistId}/checklist_items/${checklistItemId}/user_tasks.json`,
        { params }
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getChecklistTask: async (checklistId, checklistItemId, id) => {
    try {
      const response = await api.get(
        `/web/api/checklists/${checklistId}/checklist_items/${checklistItemId}/user_tasks/${id}.json`
      )

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getChecklists: async () => {
    try {
      const response = await api.get(`/web/api/checklists.json`)

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  getChecklistsAnswers: async (checklistId, params = {}) => {
    try {
      const response = await api.get(
        `/web/api/checklists/${checklistId}/user_tasks.json`,
        { params }
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  foldersEnumerator: async (type) => {
    try {
      const response = await api.get(
        `/web/api/form_folders/folders_enumerator/${type}.json`
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  checklistsEnumerators: async () => {
    try {
      const response = await api.get(`/web/api/checklists/as_enumerators.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  templatesEnumerators: async () => {
    try {
      const response = await api.get(`/web/api/templates/as_enumerators.json`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  exportChecklist: async (checklistId, formData, fileType) => {
    try {
      const response = await api({
        method: 'GET',
        url: `/web/api/checklists/${checklistId}/user_tasks.${fileType}`,
        params: formData
      })

      return response
    } catch (error) {
      handleNotificationError(error)
    }
  },
  templateQuestions: async (templateId, questionType) => {
    try {
      const response = await api.get(
        `/web/api/templates/${templateId}/template_questions.json?question_type=${questionType}`
      )
      return response?.data?.data ?? []
    } catch (error) {
      handleNotificationError(error)
    }
  },
  manageStaff: async (_, params) => {
    try {
      const response = await api.get(`/web/api/people.json`, { params })

      const responseData = response?.data
      return {
        data: orderBy(
          responseData?.data || [],
          'attributes.employment.active',
          'desc'
        ),
        meta: responseData?.meta
      }
    } catch (error) {
      handleNotificationError(error)
    }
  },
  createStaff: async (data) => {
    try {
      const response = await api.post(`/web/api/people.json`, data)

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  editStaff: async (data, id) => {
    try {
      const response = await api.put(`/web/api/people/${id}.json`, data)

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  toggleStaffActiveState: async (id) => {
    try {
      const response = await api.get(
        `/web/api/people/${id}/employments/toggle_active.json`
      )

      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  getStaff: async (id) => {
    try {
      const response = await api.get(`/web/api/people/${id}`)

      return response.data.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetTemplates: async (_, params) => {
    try {
      const response = await api.get('/web/api/asset_templates', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetTemplate: async (id) => {
    try {
      const response = await api.get(`/web/api/asset_templates/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetTemplatesEnumerator: async () => {
    try {
      const response = await api.get('/web/api/asset_templates/enumerators')
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetTemplatesAsEnumerator: async (params = {}) => {
    try {
      const response = await api.get(
        '/web/api/asset_templates/as_enumerators',
        {
          params
        }
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  newAssetTemplate: async (_, params) => {
    try {
      const response = await api.post(`/web/api/asset_templates/`, {
        asset_template: {
          ...params
        }
      })
      return response
    } catch (error) {
      throw handleErrors(error)
    }
  },
  updateAssetTemplate: async (id, params) => {
    try {
      const response = await api.put(`/web/api/asset_templates/${id}`, {
        asset_template: {
          ...params
        }
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  deleteAssetTemplate: async (id) => {
    try {
      const response = await api.delete(`/web/api/asset_templates/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  exportAssetTemplateCsv: async (id) => {
    try {
      const response = await api({
        method: 'GET',
        url: `/web/api/asset_templates/${id}/export_bulk_import_csv_model.csv`,
        responseType: 'blob'
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  importAssetTemplateCsv: async (id, object) => {
    try {
      const formData = getFormDataFromObject(object)
      const response = await api.post(
        `/web/api/asset_templates/${id}/bulk_import_assets`,
        formData
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  exportStaffCsv: async (_) => {
    try {
      const response = await api({
        method: 'GET',
        url: `/web/api/people/export_bulk_import_csv_model.csv`,
        responseType: 'blob'
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  importStaffCsv: async (_, object) => {
    try {
      const formData = getFormDataFromObject(object)
      const response = await api.post(`/web/api/people/bulk_import`, formData)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  masqueradeUser: async ({ id }) => {
    try {
      const response = await api.get(`/users/masquerade/${id}`)

      return response.data
    } catch (error) {
      handleNotificationError(error)
    }
  },
  assets: async (_, params) => {
    try {
      const response = await api.get('/web/api/assets', { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetsEnumerators: async () => {
    try {
      const response = await api.get('/web/api/assets/as_enumerators')
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetTemplateAssets: async (id, params) => {
    try {
      const response = await api.get(`/web/api/asset_templates/${id}/assets`, {
        params
      })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  exportAssetsFromAssetTemplate: async (id) => {
    try {
      const response = await api({
        method: 'GET',
        url: `/web/api/asset_templates/${id}/export_assets.xlsx`
      })

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  asset: async (id) => {
    try {
      const response = await api.get(`/web/api/assets/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  assetsEnumerator: async () => {
    try {
      const response = await api.get('/web/api/assets/enumerators')
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  newAsset: async (_, params) => {
    try {
      const response = await api.post(
        `/web/api/assets/`,
        getFormDataFromObject({ asset: params })
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  updateAsset: async (id, params) => {
    try {
      const response = await api.put(
        `/web/api/assets/${id}`,
        getFormDataFromObject({ asset: params })
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  deleteAsset: async (id) => {
    try {
      const response = await api.delete(`/web/api/assets/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  assetFormResponses: async (id, params) => {
    try {
      const response = await api.get(`/web/api/assets/${id}/form_responses`, {
        params
      })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  manageDashboardsEditorEnumerators: async () => {
    try {
      const response = await api.get(`/web/api/dashboards/enumerators.json`)
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  getNotifications: async () => {
    try {
      const response = await api.get('/web/api/notifications')
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  manageDashboards: async (_, params) => {
    try {
      const response = await api.get(`/web/api/dashboards`, { params })
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  manageDashboard: async (id) => {
    try {
      const response = await api.get(`/web/api/dashboards/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw error
    }
  },
  newDashboard: async (_, params) => {
    try {
      const response = await api.post(`/web/api/dashboards/`, {
        dashboard: params
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  updateDashboard: async (id, params) => {
    try {
      const response = await api.put(`/web/api/dashboards/${id}`, {
        dashboard: params
      })
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  deleteDashboard: async (id) => {
    try {
      const response = await api.delete(`/web/api/dashboards/${id}`)
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  getDashboardData: async (id, chartId, params = {}) => {
    try {
      params.day_range = params?.name
      if (!isEmpty(params?.params?.date)) {
        const [start_date, end_date] = params?.params?.date
        params.start_date = start_date
        params.end_date = end_date
      }
      const response = await api.get(
        `/web/api/dashboards/${id}/chart_data/${chartId}`,
        {
          params: pick(params, ['start_date', 'end_date', 'day_range'])
        }
      )
      return response.data
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  reorderDashboards: async (params) => {
    try {
      const response = await api.post(`/web/api/dashboards/reorder`, {
        ...params
      })

      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  },
  notificationMarkAsRead: async (ids) => {
    try {
      const response = await api.post(
        '/web/api/notifications/mark_as_read.json',
        {
          ids
        }
      )
      return response
    } catch (error) {
      handleNotificationError(error)
      throw handleErrors(error)
    }
  }
}

export default OlingaAPI
